<template>
    <div class="Useoffunds">
        <el-table 
            :data="tableData" 
            style="width: 100%;min-height:200px"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="money" label="金额"></el-table-column>
            <el-table-column prop="bill" label="票据">
                <template slot-scope="scope">
                    <img :src="scope.row.bill" alt="" width="100" height="80">
                </template>
            </el-table-column>
        </el-table>
        <div class="next_step fl">
            <el-button type="primary" @click="previous('ruleForm')">上一步</el-button>
            <el-button type="primary" @click="newtion('ruleForm')">提交</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
           tableData:[], 
        }
    },
    methods: {
        newtion(){
            this.$router.push('/Viewclosingreport')
        }
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Postprojectreport.css';
</style>