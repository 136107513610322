import { render, staticRenderFns } from "./Useoffunds.vue?vue&type=template&id=26164ff2&scoped=true&"
import script from "./Useoffunds.vue?vue&type=script&lang=js&"
export * from "./Useoffunds.vue?vue&type=script&lang=js&"
import style0 from "./Useoffunds.vue?vue&type=style&index=0&id=26164ff2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26164ff2",
  null
  
)

export default component.exports